const ValuesEthos = () => (
  <div className="ValuesEthos">
    <h2>VALUES, ETHOS and POLICIES</h2>
    <a href="/INPAC_VALUES_ETHOS_and_POLICIES.pdf" target="_blank">
      <button>Download pdf</button>
    </a>
  </div>
);

export default ValuesEthos;
